import Ls from "@/core/services/jwt.service.js";
export default {
  data() {},

  created() {},

  methods: {
    saveDataSearchToLs(key, data) {
      let data_search_ls = Ls.getInputSearch();
      let data_search = {};
      if (data_search_ls != null) {
        data_search = JSON.parse(data_search_ls);
      }
      data_search[key] = data;
      Ls.saveInputSearch(data_search);
    },
    getDataSearchByKey(key) {
      let data_search_ls = JSON.parse(Ls.getInputSearch());
      if (data_search_ls === null || data_search_ls === undefined) return null;
      let data_search = data_search_ls[key];
      if (data_search === null || data_search === undefined) return null;
      return data_search;
    },
  },
};
