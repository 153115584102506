<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Danh sách coupon</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
<!--      <v-btn-->
<!--        rounded-->
<!--        color="primary"-->
<!--        class="mb-5"-->
<!--        x-small-->
<!--        large-->
<!--        @click="openDialogCoupon"-->
<!--      >-->
<!--        <v-icon color="white" dark>mdi-plus</v-icon-->
<!--        ><span style="color: white">Thêm Coupon</span>-->
<!--      </v-btn>-->
      <div>
        <v-row>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-2"
              style="float: right; width: 300px"
              placeholder="Search"
              v-model="input_search"
              @keyup.enter="actionSearchCoupon()"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Code</th>
                    <th scope="col">Name</th>
                    <!--                    <th scope="col">Cover</th>-->
                    <th scope="col">Source</th>
                    <th scope="col">Total Programme</th>
                    <th scope="col">Date</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Used</th>
                    <th scope="col">Can combined</th>
                    <th scope="col">Discount Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(coupon, i) in coupons">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          style="max-width: 120px; word-break: break-word"
                          class="text-dark-75 font-weight-bolder font-size-lg"
                        >
                          {{ coupon.code }}
                        </p>
                      </td>
                      <td>
                        <p
                          style="max-width: 180px; word-break: break-word"
                          class="text-dark-75 font-weight-bolder font-size-lg"
                        >
                          {{ coupon.name }}
                        </p>
                      </td>
                      <!--                      <td>-->
                      <!--                        <v-img v-if="coupon.cover" :src="coupon.cover" max-width="100" max-height="70" class="rounded-lg"></v-img>-->
                      <!--                      </td>-->
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ transferSource(coupon.source) }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ coupon.programmes.length }}
                        </p>
                      </td>
                      <td>
                        <p>
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >{{
                              coupon.from_date | convertTimestampToDate
                            }}</span
                          >
                          <v-icon class="ml-2"
                            >mdi-arrow-right-bold-outline</v-icon
                          >
                          <span
                            class="ml-2 text-dark-75 font-weight-bolder font-size-lg"
                            >{{ coupon.to_date | convertTimestampToDate }}</span
                          >
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ coupon.quantity | formatNumber }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ coupon.used | formatNumber }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg light-blue--text text--lighten-1"
                          v-if="coupon.can_combined === 1"
                        >
                          Có thể dùng chung coupon khác
                        </p>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg cyan--text text--lighten-1"
                          v-if="coupon.can_combined === 0"
                        >
                          Chỉ dùng được 1 coupon
                        </p>
                      </td>
                      <!-- <td>
                        <p class="text-dark-75 font-weight-bolder d-block font-size-lg blue--text text--lighten-1">{{coupon.discount_amount | formatNumber}} VND</p>
                      </td> -->

                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg pt-4 blue--text text--lighten-1"
                          v-if="coupon.discount_type === 1"
                        >
                          {{ coupon.discount_amount | formatNumber }} VND
                        </p>
                        <span
                          class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                          v-if="coupon.discount_type === 2"
                          >{{ coupon.discount_amount }} %</span
                        >
                      </td>

                      <td>
                        <span style="width: 137px" v-if="coupon.status === 1"
                          ><span
                            class="label label-success label-dot mr-2"
                          ></span
                          ><span class="font-weight-bold text-success"
                            >Active</span
                          ></span
                        >
                        <span style="width: 137px" v-if="coupon.status === 2"
                          ><span
                            class="label label-warning label-dot mr-2"
                          ></span
                          ><span class="font-weight-bold text-primary"
                            >Expired</span
                          ></span
                        >
                        <span style="width: 137px" v-if="coupon.status === 3"
                          ><span
                            class="label label-primary label-dot mr-2"
                          ></span
                          ><span class="font-weight-bold text-primary"
                            >Archived</span
                          ></span
                        >
                      </td>
                      <td>
<!--                        <div style="display: flex">-->
<!--                          <v-tooltip left>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-success btn-sm"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="detailCoupon(i, coupon.id)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-square-edit-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Edit</span>-->
<!--                          </v-tooltip>-->
<!--                          <v-tooltip left>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-primary btn-sm ml-2"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="showOrderList(coupon.id)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-clipboard-list-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Danh sách order</span>-->
<!--                          </v-tooltip>-->
<!--                          <v-tooltip right>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-warning btn-sm ml-2"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="btnArchived(coupon.id)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-archive-arrow-up-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Archive</span>-->
<!--                          </v-tooltip>-->
<!--                        </div>-->
                      </td>
                    </tr>
                  </template>
                  <template v-if="!coupons.length">
                    <tr>
                      <td style="text-align: center" colspan="12">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              class="mt-5"
              color="#00BCD4"
              style="float: left"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogCoupon" max-width="1200px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_coupon === 'add'" class="headline"
                >Add Coupon</span
              >
              <span v-if="type_form_coupon === 'update'" class="headline"
                >Edit Coupon</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogCoupon = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" class="pt-0">
                  <v-text-field
                    label="Code"
                    v-model="code_input"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-text-field
                    label="Name"
                    v-model="name_input"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="5">
                  <label style="font-size: 16px; width: 30%" class="mt-1 p-0"
                    >Từ ngày:
                  </label>
                  <date-picker
                    style="width: 70%"
                    class=""
                    v-model="from_date_input"
                    value-type="format"
                    format="YYYY-MM-DD"
                  />
                </v-col>
                <v-col cols="1">
                  <v-icon class="mt-2 ml-4"
                    >mdi-arrow-right-bold-outline</v-icon
                  >
                </v-col>
                <v-col cols="5">
                  <label
                    style="font-size: 16px; width: 30%"
                    class="mt-1 p-0 col-3"
                    >Đến ngày:
                  </label>
                  <date-picker
                    style="width: 70%"
                    class=""
                    v-model="to_date_input"
                    value-type="format"
                    format="YYYY-MM-DD"
                  />
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Mô tả</label>
                  <Editor v-model="description_input"></Editor>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-text-field
                    type="number"
                    label="Số lương"
                    v-model="quantity_input"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="6" class="d-flex pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0 col-4"
                    >Kết hợp CT giảm khác:
                  </label>
                  <v-radio-group
                    v-model="can_combined_input"
                    row
                    class="mt-0 p-0 ml-1 col-7"
                  >
                    <v-radio label="Có" value="yes"></v-radio>
                    <v-radio label="Không" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="7" class="d-flex mt-4 pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0 col-3"
                    >Giảm theo:
                  </label>
                  <v-radio-group
                    v-model="type_input"
                    row
                    class="mt-0 p-0 ml-1 col-9"
                  >
                    <v-radio label="Giảm trực tiếp" :value="1"></v-radio>
                    <v-radio label="Giảm theo %" :value="2"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="3">
                  <div class="d-flex">
                    <!--                    <v-text-field type="text" label="Discount Amount" :value="formatAmount(amount_input)" @input="setAmount"></v-text-field>-->
                    <v-text-field
                      type="number"
                      label="Số tiền giảm"
                      v-model="amount_input"
                    ></v-text-field>
                    <p
                      v-if="type_input === 1"
                      class="mt-6 text-h6 font-weight-bold"
                    >
                      VNĐ
                    </p>
                    <p
                      v-if="type_input === 2"
                      class="mt-6 text-h6 font-weight-bold"
                    >
                      %
                    </p>
                    <p
                      v-if="type_input === 1"
                      class="text-subtitle-1 mt-7 ml-2"
                    >
                      ({{ amount_input | formatNumber }})
                    </p>
                  </div>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Nguồn</label>
                  <v-autocomplete
                    v-model="source_input"
                    :items="source_coupon"
                    color="blue-grey lighten-2"
                    filled
                    item-text="label"
                    item-value="value"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Referrer</label>
                  <v-autocomplete
                    v-model="referrer_input"
                    :items="list_referrer"
                    color="blue-grey lighten-2"
                    filled
                    item-text="referrer"
                    item-value="id"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <label style="font-size: 16px">Product Category</label>
                  <v-autocomplete
                    v-model="product_category_ids"
                    :items="product_categories"
                    item-text="name.vi"
                    item-value="id"
                    color="blue-grey lighten-2"
                    filled
                    chips
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <label style="font-size: 16px">Roadmap</label>
                  <v-autocomplete
                    v-model="roadmap_ids"
                    :items="roadmaps"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    item-text="name"
                    item-value="id"
                    multiple
                    deletable-chips
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <label style="font-size: 16px">Gói dịch vụ</label>
                  <v-autocomplete
                      v-model="service_package_for_sale_ids"
                      :items="list_service_package_for_sale"
                      filled
                      chips
                      color="blue-grey lighten-2"
                      item-text="name"
                      item-value="id"
                      multiple
                      deletable-chips
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" class="d-flex pt-0 pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0"
                    >Áp dụng tất cả chương trình:
                  </label>
                  <v-switch
                    class="mt-0 pt-0 ml-2"
                    v-model="is_apply_all_programme"
                    :label="is_apply_all_programme === true ? 'Yes' : 'No'"
                  ></v-switch>
                </v-col>
                <v-col cols="6" class="p-0 pr-4">
                  <v-text-field
                    type="number"
                    label="Số lương chương trình tối thiểu để áp dụng"
                    v-model="minimum_programme_quantity_input"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="pt-0"
                  v-if="is_apply_all_programme === false"
                >
                  <label style="font-size: 16px">Chương trình</label>
                  <v-autocomplete
                    v-model="programme_ids"
                    :disabled="isUpdating"
                    :items="programme_tags"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label=""
                    item-text="name.vi"
                    item-value="id"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeOptionTag(data.item, 'programme')"
                      >
                        {{ data.item.name.vi }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name.vi"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <label style="font-size: 16px">Tặng chương trình</label>
                  <v-autocomplete
                    v-model="gift_programme_ids"
                    :items="programme_tags"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label=""
                    item-text="name.vi"
                    item-value="id"
                    multiple
                  >
                  </v-autocomplete>
                </v-col>
                <!--                <v-col cols="12">-->
                <!--                  <v-file-input small-chips label="Cover" @change="onElementChange" accept="image/*"></v-file-input>-->
                <!--                  <v-img :src="cover_input"></v-img>-->
                <!--                </v-col>-->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_coupon === 'add'"
              @click="btnAddOrEditCoupon(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_coupon === 'update'"
              @click="btnAddOrEditCoupon(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogCoupon = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <OrderListModal
      :coupon_id="coupon_id"
      :show_dialog="dialogOrderList"
      @setDialog="(e) => (this.dialogOrderList = e)"
    ></OrderListModal>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import handle_ls_search from "@/mixins/handle_ls_search.js";
import OrderListModal from "@/view/pages/coupons/modal/OrderList";
export default {
  name: "CouponList",
  mixins: [handle_ls_search],
  components: {
    OrderListModal,
    Loading: () => import("vue-loading-overlay"),
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
  },
  data() {
    return {
      is_call_api: false,
      coupons: [],
      dialogCoupon: false,
      type_form_coupon: null,
      programme_ids: [],
      programme_tags: [],
      isUpdating: false,
      code_input: "",
      name_input: "",
      amount_input: 0,
      from_date_input: null,
      to_date_input: null,
      description_input: "",
      quantity_input: null,
      can_combined_input: "no",
      cover_input: null,
      cover_file: null,
      coupon_id: null,
      type_input: 1,
      input_search: "",
      is_apply_all_programme: false,
      dialogOrderList: false,
      source_coupon: [],
      source_input: null,
      gift_programme_ids: [],
      product_categories: [],
      product_category_ids: [],
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      roadmaps: [],
      roadmap_ids: [],
      minimum_programme_quantity_input: null,
      list_referrer: [],
      referrer_input: null,
      list_service_package_for_sale: [],
      service_package_for_sale_ids: [],
    };
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    // input_search(val) {
    //   this.actionSearchCoupon(val);
    // },
    "data_pagination.current_page": async function (page) {
      this.is_call_api = true;
      await this.getAllCoupon(page, this.input_search);
      this.is_call_api = false;
    },
    source_input(val) {
      if (val) {
        let source_txt = this.source_coupon.filter(e => e.value === val)[0].label;
        this.getReferrerBySource(source_txt)
      } else {
        this.list_referrer = [];
      }
    }
  },
  created() {
    this.is_call_api = true;
    let data_search = this.getDataSearchByKey("coupon");
    if (data_search) {
      this.input_search = data_search.query ? data_search.query : "";
    }
    this.getAllProgramme();
    this.getAllProductCategory();
    this.getAllCoupon(1, this.input_search);
    this.getListSourceCoupon();
    this.getAllRoadmap();
    this.getServicePackageForSale();
    this.is_call_api = false;
  },
  methods: {
    async getListSourceCoupon() {
      let vm = this;
      await ApiService.get("prep-app/coupon/list-source").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.source_coupon = response.data;
        }
      });
    },
    async getAllProgramme() {
      let vm = this;
      await ApiService.get("prep-app/programme/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.programme_tags = response.data;
        }
      });
    },
    async getAllProductCategory() {
      let vm = this;
      await ApiService.get("prep-app/product-category/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.product_categories = response.data;
        }
      });
    },
    async getAllCoupon(page = 1, query = "") {
      let vm = this;
      vm.is_call_api = true;
      await ApiService.get(
        "prep-app/coupon?page=" + page + "&name=" + query
      ).then(function (response) {
        if (response.status === 200) {
          vm.coupons = response.data.data;
          vm.data_pagination = response.data.meta.pagination;
          vm.is_call_api = false;
        }
      });
    },
    async getAllRoadmap() {
      let vm = this;
      await ApiService.get("prep-app/roadmap/limit").then(function (response) {
        if (response.status === 200) {
          vm.roadmaps = response.data;
        }
      });
    },
    openDialogCoupon() {
      this.type_form_coupon = "add";
      this.programme_ids = [];
      this.defaultDataCoupon();
      this.dialogCoupon = true;
    },
    defaultDataCoupon() {
      this.code_input = "";
      this.name_input = "";
      this.amount_input = 0;
      this.from_date_input = null;
      this.to_date_input = null;
      this.description_input = "";
      this.quantity_input = null;
      this.can_combined_input = "no";
      this.cover_input = null;
      this.cover_file = null;
      this.type_input = 1;
      this.is_apply_all_programme = false;
      this.source_input = null;
      this.gift_programme_ids = [];
      this.product_category_ids = [];
      this.roadmap_ids = [];
      this.minimum_programme_quantity_input = null;
      this.service_package_for_sale_ids = [];
    },
    validateForm() {
      // let vm = this;
      let flat = true;
      if (this.name_input === "") {
        this.$toasted.error("Hãy nhập tên cho coupon !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.code_input === "") {
        this.$toasted.error("Hãy nhập code cho coupon !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.from_date_input == null) {
        this.$toasted.error("Hãy nhập thời gian bắt đầu coupon !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.to_date_input == null) {
        this.$toasted.error("Hãy nhập thời gian kết thúc coupon !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.quantity_input <= 0) {
        this.$toasted.error("Hãy nhập quantity và phải lớn hơn 0 !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      if (this.service_package_for_sale_ids.length <= 0) {
        if (this.minimum_programme_quantity_input <= 0) {
          this.$toasted.error("Số lương áp dụng chương trình tối thiểu phải lớn hơn 0 !!", { theme: "toasted-primary", position: "top-right", duration: 4000 });
          flat = false;
        }
      }
      if (this.source_input == null) {
        this.$toasted.error("Hãy chọn nguồn coupon !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
        flat = false;
      }
      return flat;
    },
    async btnAddOrEditCoupon(type) {
      let validateForm = this.validateForm();
      if (!validateForm) {
        return;
      }
      let vm = this;
      let formData = new FormData();
      formData.append("code", this.code_input);
      formData.append("name", this.name_input);
      formData.append("description", this.description_input);
      formData.append("quantity", this.quantity_input);
      formData.append("from_date", this.from_date_input);
      formData.append("to_date", this.to_date_input);
      formData.append("programmes_ids", this.programme_ids);
      formData.append("discount_type", this.type_input);
      // formData.append('discount_amount', this.amount_input.replaceAll(',',''));
      formData.append("discount_amount", this.amount_input);
      formData.append("can_combined", this.can_combined_input === "yes" ? 1 : 0);
      formData.append("is_apply_all_programme", this.is_apply_all_programme === true ? 1 : 0);
      formData.append("source", this.source_input);
      if (this.minimum_programme_quantity_input) {
        formData.append("minimum_programme_quantity", this.minimum_programme_quantity_input);
      }
      if (this.gift_programme_ids.length > 0)
        formData.append("gift_programmes", this.gift_programme_ids);
      if (this.product_category_ids.length > 0)
        formData.append("product_category_ids", this.product_category_ids);
      if (this.roadmap_ids.length > 0)
        formData.append("roadmap_ids", this.roadmap_ids);
      if (this.cover_file != null) {
        formData.append("cover", this.cover_file);
      }
      if (this.referrer_input) formData.append("referrer", this.referrer_input);
      if (this.service_package_for_sale_ids.length > 0)
        formData.append("service_package_for_sale_ids", this.service_package_for_sale_ids);
      if (type === 1) {
        vm.is_call_api = true;
        await ApiService.post("prep-app/coupon", formData)
          .then(function (res) {
            if (res.status === 201) {
              vm.is_call_api = false;
              vm.getAllCoupon();
              Swal.fire({title: "", text: "The application has been successfully submitted!", icon: "success", confirmButtonClass: "btn btn-secondary",});
              vm.dialogCoupon = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              vm.is_call_api = false;
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        vm.is_call_api = true;
        await ApiService.post("prep-app/coupon/" + vm.coupon_id, formData)
          .then(function (res) {
            if (res.status === 202) {
              vm.is_call_api = false;
              vm.getAllCoupon();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.dialogCoupon = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              vm.is_call_api = false;
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    detailCoupon(i, coupon_id) {
      this.type_form_coupon = "update";
      this.coupon_id = coupon_id;
      this.code_input = this.coupons[i].code;
      this.name_input = this.coupons[i].name;
      // this.amount_input = this.formatAmount(this.coupons[i].discount_amount);
      this.amount_input = this.coupons[i].discount_amount;
      this.from_date_input = this.$options.filters.convertTimestampToDate(
        this.coupons[i].from_date
      );
      this.to_date_input = this.$options.filters.convertTimestampToDate(
        this.coupons[i].to_date
      );
      this.can_combined_input =
        this.coupons[i].can_combined === 0 ? "no" : "yes";
      this.cover_input = this.coupons[i].cover;
      this.cover_file = null;
      this.programme_ids = this.coupons[i].programmes.map((programme) => {
        return programme.id;
      });
      this.quantity_input = this.coupons[i].quantity;
      this.description_input =
        this.coupons[i].description === null ? "" : this.coupons[i].description;
      this.type_input = this.coupons[i].discount_type;
      this.is_apply_all_programme = this.coupons[i].is_apply_all_programme === 1;
      this.source_input = this.coupons[i].source;
      this.gift_programme_ids = this.coupons[i].gift_programmes.map((programme) => programme.id);
      this.product_category_ids = this.coupons[i].product_categories.map((product_category) => product_category.id);
      this.roadmap_ids = this.coupons[i].roadmaps.map((item) => item.roadmap_id);
      this.minimum_programme_quantity_input = this.coupons[i].minimum_programme_quantity;
      this.service_package_for_sale_ids = this.coupons[i].service_packages.map((item) => item.id);
      this.dialogCoupon = true;
    },
    btnArchived(coupon_id) {
      let self = this;
      Swal.fire({
        title: "Ban chắc chán muốn thay đổi?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Đúng!",
        cancelButtonText: "Hủy",
      }).then((result) => {
        if (result.value) {
          ApiService.post("prep-app/coupon/" + coupon_id + "/archived")
            .then(function (res) {
              if (res.status === 202) {
                self.getAllCoupon();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    removeOptionTag(item, type) {
      let index;
      if (type === "programme") {
        index = this.programme_ids.indexOf(item.id);
        if (index >= 0) this.programme_ids.splice(index, 1);
      }
    },
    setAmount($event) {
      this.amount_input = $event;
    },
    formatAmount(value) {
      let stringMoney = value.toString();
      stringMoney.replace(",", "");
      let money = parseInt(stringMoney.replace(/\D/g, ""), 10);
      if (Number.isNaN(money)) return 0;
      return money.toLocaleString();
    },
    onElementChange(file) {
      let vm = this;
      if (file === undefined) {
        vm.cover_input = null;
        vm.cover_file = null;
        return;
      }

      let reader = new FileReader();
      reader.onload = (e) => {
        vm.cover_input = e.target.result;
        vm.cover_file = file;
      };
      reader.readAsDataURL(file);
    },
    actionSearchCoupon() {
      let query = this.input_search;
      let vm = this;
      if (this.timer_query) {
        clearTimeout(this.timer_query);
        this.timer_query = null;
      }
      this.timer_query = setTimeout(() => {
        let coupon = {
          query: query,
        };
        vm.saveDataSearchToLs("coupon", coupon);
        vm.getAllCoupon(1, query);
      }, 1200);
    },
    showOrderList(coupon_id) {
      this.coupon_id = coupon_id;
      this.dialogOrderList = true;
    },
    transferSource(source_value) {
      let data = this.source_coupon.filter((e) => {
        return e.value === source_value;
      });
      if (data.length) {
        return data[0].label;
      } else {
        return "";
      }
    },
    async getReferrerBySource(source_txt) {
      let vm = this;
      vm.is_call_api = true;
      await ApiService.get("prep-app/coupon/get-referrer-by-source?source="+source_txt)
          .then(function (response) {
            vm.is_call_api = false;
            if (response.status === 200) {
              vm.list_referrer = response.data;
            }
          })
          .catch(function (error) {
            console.log(error)
            vm.is_call_api = false;
            vm.$toasted.error("Lấy danh sách referrer theo source lỗi !!", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          });
    },
    async getServicePackageForSale() {
      let vm = this;
      await ApiService.get("prep-app/service-package/list-for-sale").then(function (response) {
        if (response.status === 200) {
          vm.list_service_package_for_sale = response.data;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
